exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-search-results-tsx": () => import("./../../../src/pages/search-results.tsx" /* webpackChunkName: "component---src-pages-search-results-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-blogpost-tsx": () => import("./../../../src/templates/blogpost.tsx" /* webpackChunkName: "component---src-templates-blogpost-tsx" */),
  "component---src-templates-city-tsx": () => import("./../../../src/templates/city.tsx" /* webpackChunkName: "component---src-templates-city-tsx" */),
  "component---src-templates-continent-tsx": () => import("./../../../src/templates/continent.tsx" /* webpackChunkName: "component---src-templates-continent-tsx" */),
  "component---src-templates-country-tsx": () => import("./../../../src/templates/country.tsx" /* webpackChunkName: "component---src-templates-country-tsx" */),
  "component---src-templates-region-tsx": () => import("./../../../src/templates/region.tsx" /* webpackChunkName: "component---src-templates-region-tsx" */),
  "component---src-templates-resort-tsx": () => import("./../../../src/templates/resort.tsx" /* webpackChunkName: "component---src-templates-resort-tsx" */)
}

