
export const monthSlugs: string[] = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december"
];
export const monthSlugsWithOverview: string[] = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
    "climate"
];
export const continents: string[] = [
    "europe",
    "asia",
    "middle-east",
    "central-america",
    "south-america",
    "north-america",
    "africa",
    "australia"
];
export const measures: string[] = [
    "tmax",
    "sst",
    "ppt",
    "tmin",
    "wet",
    "sunhrs",
    "ws",
    "rhm"
];
export const activities: string[] = ["beach", "city", "nature"];
export const continentsCentroidsAndZooms: {
    [key: string]: {
        bounds: [number, number][];
        center?: [number, number];
        zoom?: number;
    };
} = {
    europe: {
        bounds: [
            [26, -33],
            [68, 40]
        ]
    },
    "north-america": {
        bounds: [
            [23, -155],
            [67, -53]
        ]
    },
    "south-america": {
        bounds: [
            [-55, -90],
            [13, -31]
        ]
    },
    "central-america": {
        bounds: [
            [5, -120],
            [27, -56]
        ]
    },
    asia: {
        bounds: [
            [-12, 68],
            [48, 150]
        ]
    },
    africa: {
        bounds: [
            [-38, -24],
            [37, 62]
        ]
    },
    "middle-east": {
        bounds: [
            [10, 22],
            [45, 61]
        ]
    },
    australia: {
        bounds: [
            [-48, 108],
            [0, 223]
        ]
    },
    all: {
        bounds: [
            [-70, -170],
            [70, 170]
        ],
        zoom: 2,
        center: [0, 0]
    }
};
