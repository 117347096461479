import "leaflet/dist/leaflet.css";
import * as React from "react";
import { AppContext } from "./src/context/app-context";
import { PageContext } from "./src/context/page-context";
import "./src/declaration.d.ts";
import "./src/styles/global.css";

export const wrapRootElement = ({ element }) => {
    return <AppContext>{element}</AppContext>;
};

export const wrapPageElement = ({ element, props }) => {
    let monthParam = null;
    if (window?.location?.search) {
        const urlParams = new URLSearchParams(window.location.search);
        monthParam = urlParams.get("month");
        var yourCurrentUrl = window.location.href.split("?")[0];
        window.history.replaceState({}, "", yourCurrentUrl);
    }
    return (
        <PageContext month={monthParam || props?.pageContext?.month}>
            {element}
        </PageContext>
    );
};
export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {
    const currentPosition = getSavedScrollPosition(location);
    if (!location.hash) {
        setTimeout(() => {
            window &&
                window.scrollTo({
                    top: currentPosition[0] || 0,
                    left: currentPosition[1] || 0,
                    behavior: "instant"
                });
        }, 0);
        return false;
    }
};
