import * as React from "react";
import { createContext } from "react";

const initialValues = {
    lang: "en",
    temp: "C"
};

export const AppContext = ({ children }: { children: React.ReactNode }) => {
    const [language, setLanguage] = React.useState(initialValues.lang);
    const [temperatureUnit, setTemperatureUnit] = React.useState(
        initialValues.temp
    );

    return (
        <>
            <LanguageContext.Provider
                value={[language, (lang) => setLanguage(lang)]}
            >
                <TemperatureUnitContext.Provider
                    value={[
                        temperatureUnit,
                        (temp) => setTemperatureUnit(temp)
                    ]}
                >
                    {children}
                </TemperatureUnitContext.Provider>
            </LanguageContext.Provider>
        </>
    );
};

export const LanguageContext = createContext<[string, (a: string) => void]>([
    null,
    (_) => {}
]);
export const TemperatureUnitContext = createContext<
    [string, (a: string) => void]
>([null, (_) => {}]);
