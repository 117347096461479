import * as React from "react";
import { createContext } from "react";
//import { useAppDispatch } from "../hooks/useAppDispatch";
import { monthSlugsWithOverview } from "../services/centralStore";
import getDefaultMonthIndex from "../services/getDefaultMonthIndex";
import {
    setMonth as setMonthAction,
    valueChanged
} from "../store/weatherSearchSlice";

export const PageContext = ({
    month,
    children
}: {
    month?: string;
    children: React.ReactNode;
}) => {
    //const dispatch = useAppDispatch();
    const [activeMonth, setActiveMonth] = React.useState(
        month || monthSlugsWithOverview[getDefaultMonthIndex()]
    );
    return (
        <>
            <ActiveMonthContext.Provider value={[activeMonth, setActiveMonth]}>
                {children}
            </ActiveMonthContext.Provider>
        </>
    );
};

export const ActiveMonthContext = createContext<[string, (a: string) => void]>([
    null,
    (_) => {}
]);
